import React from "react"
import { TwitterTimelineEmbed } from "react-twitter-embed"

const Twitter = () => {
	return (
		<div
			style={{margin: '0 auto 5em',maxWidth:'500px',height: '100%'}}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="TOKYO04455693"
          options={{
            width: "100%",
            height: "450"
          }}
          noBorders="true"
          noFooter="true"
					id="TOKYO04455693"
					lang="ja"
        ></TwitterTimelineEmbed>
		</div>
	)
}

export default Twitter

