import React from 'react'
// import { graphql, StaticQuery } from 'gatsby'
import styled from "styled-components"

// import BackgroundImage from 'gatsby-background-image'
import iconInstagram from '../assets/images/icon-instagram.svg'
import BgImage from '../assets/images/item-box.png'

const StyledBackgroundImage = styled.div`
  .item-box__bg {
    background-image: url(${BgImage});
    height: 72vh;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 85%;
    background-size: 94% ;
    .item-box__text {
      padding-top: 3.5em;
      padding-bottom: 3.5em;
      text-align: left;
      .item-box__head {
        font-weight: 600;
      }
      p {
        margin-bottom: 8px;
      }
      .item-box__icon {
        max-width: 18px;
        margin-right: 8px;
        margin-top: -4px;
      }
      .item-box__link {
        /* color: $accent-color; */
        text-decoration: underline;
      }
    }
  }
  @media (min-width: 768px) {
    .item-box__bg {
      height: 60vh;
      display: flex;
      align-items: center;
      background-size: 60%;
      background-attachment: fixed;
      background-position: right center;
      .item-box__text {
        padding-top: 2em;
        .item-box__head {
          font-weight: 600;
        }
        .item-box__link {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) {
    .item-box__bg {
      height: 80vh;
    }
  }
  @media (min-width: 1200px) {
    .item-box__bg {
      max-width: 1400px;
      margin: auto;
      &::before {
        background-size: 800px;
        background-position: 80% center;
      }
    }
  }
}
`
const BackgroundSection = () => {
  return (
    <StyledBackgroundImage>

    <div className="item-box__bg">
      <div className="item-box__text container">
        <h3 className="item-box__head">What’s your favorite flavor?</h3>
        <p>あなたのお気に入りフレーバーを<br />
          みんなにシェアしちゃおう！</p>
          <img className="item-box__icon" src={iconInstagram} alt="天麩羅TOKYO Instagram" />
          #
          <a 
          className="item-box__link"
          href="https://www.instagram.com/explore/tags/%E5%A4%A9%E9%BA%A9%E7%BE%85tokyo/"
          target="_blank" 
          rel="noopener noreferrer"
          >
          天麩羅tokyo
          </a>
      </div>
    </div>
    </StyledBackgroundImage>
  )
}


export default BackgroundSection