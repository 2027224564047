import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Fade from "react-reveal/Fade"

const StoresList = () => {
  const data = useStaticQuery(graphql`
    query {
      imageToranomon: file(relativePath: { eq: "stores-toranomon.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageHikarie: file(relativePath: { eq: "stores-hikarie.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageDaimaru: file(relativePath: { eq: "stores-daimaru.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFutako: file(relativePath: { eq: "stores-futako.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Fade bottom duration={1000} distance={"50px"} delay={100}>
        <div className="stores-box">
          {/* <img className="" src={imageToranomon} alt="虎ノ門本店" /> */}
          <Image fluid={data.imageToranomon.childImageSharp.fluid} />
          <div className="stores-text">
            <h3 className="stores-header">ホタルノヒカリ 虎ノ門本店</h3>
            <p>
              〒105-0001
              <br />
              東京都港区虎ノ門2丁目5-5 ニュー虎ノ門ビル1F
              <br />
              03-6205-7877
            </p>
            <p>定休日：不定休</p>
            <a
              href="https://g.page/hotahika?share"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Map
            </a>
          </div>
        </div>
      </Fade>

      <Fade bottom duration={1000} distance={"50px"} delay={200}>
        <div className="stores-box">
          {/* <img className="" src={imageHikarie} alt="Shibuya Hikarie店" /> */}
          <Image fluid={data.imageHikarie.childImageSharp.fluid} />
          <div className="stores-text">
            <h3 className="stores-header">ホタルノヒカリ Shibuya Hikarie店</h3>
            <p>
              〒150-8509
              <br />
              東京都渋谷区渋谷2-21-1 渋谷ヒカリエ ShinQs B2F
              <br />
              03-6434-1860
            </p>
            <a
              href="https://goo.gl/maps/712BfPoWN5WtcYJcA"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Map
            </a>
          </div>
        </div>
      </Fade>

      <Fade bottom duration={1000} distance={"50px"} delay={300}>
        <div className="stores-box">
          <Image fluid={data.imageDaimaru.childImageSharp.fluid} />
          <div className="stores-text">
            <h3 className="stores-header">ホタルノヒカリ 大丸心斎橋店</h3>
            <p>
              〒542-8501
              <br />
              大阪府大阪市中央区心斎橋筋 1-7-1　大丸心斎橋店 B1F
            </p>
            <a
              href="https://goo.gl/maps/u7HVEhd43Vgfxz7H6"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Map
            </a>
          </div>
        </div>
      </Fade>

      <Fade bottom duration={1000} distance={"50px"} delay={400}>
        <div className="stores-box">
          <Image fluid={data.imageFutako.childImageSharp.fluid} />
          <div className="stores-text">
            <h3 className="stores-header">ホタルノヒカリ 二子玉川店</h3>
            <p>
              〒158-0094
              <br />
              東京都世田谷区玉川 2-21-1 二子玉川ライズ・ショッピングセンターB1F
              <br />
              03-6805-7189
            </p>
            <a
              href="https://goo.gl/maps/UYx6uec8w2vxiyRq9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Map
            </a>
          </div>
        </div>
      </Fade>
    </>
  )
}

export default StoresList
