import React from "react"
import Image from 'gatsby-image'

const FlavorItem = (props) => {
  return (
		<div className={`flavor-box ${props.flavorSlug}`}>
			<Image className="flavor-image" fluid={props.flavorImage} alt={props.flavorName} />
			<div className="flavor-text">
				<div className="flavor-header">
					<div className="flavor-header-jp">
						<img className="flavor-icon" src={props.flavorIcon} alt={props.flavorName} />
						<h3 className="flavor-name">{props.flavorName}</h3>
					</div>
					<div className="flavor-name-en">{props.flavorNameEn}</div>
				</div>
				<div className="flavor-description">
					{props.flavorDescription}
				</div>
			</div>
		</div>
  )
}

export default FlavorItem