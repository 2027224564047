import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import styled from "styled-components"

import FlavorItem from './FlavorItem'

import iconLemon from '../assets/images/icon-flavor-lemon.svg'
import iconUme from '../assets/images/icon-flavor-ume.svg'
import iconChili from '../assets/images/icon-flavor-chili.svg'
import iconWasabi from '../assets/images/icon-flavor-wasabi.svg'
import iconTruffle from '../assets/images/icon-flavor-truffle.svg'

// import imageFlavorLemon from '../assets/images/flavor-lemon.png'
// import imageFlavorUme from '../assets/images/flavor-ume.png'
// import imageFlavorChili from '../assets/images/flavor-chili.png'
// import imageFlavorWasabi from '../assets/images/flavor-wasabi.png'
// import imageFlavorTruffle from '../assets/images/flavor-truffle.png'


const FlavorSlick = () => {
  const data = useStaticQuery(graphql`
    query {
      imageFlavorLemon: file(relativePath: { eq: "flavor-lemon.png" }) {
        childImageSharp {
          fluid(maxWidth: 710) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFlavorUme: file(relativePath: { eq: "flavor-ume.png" }) {
        childImageSharp {
          fluid(maxWidth: 710) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFlavorChili: file(relativePath: { eq: "flavor-chili.png" }) {
        childImageSharp {
          fluid(maxWidth: 710) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFlavorWasabi: file(relativePath: { eq: "flavor-wasabi.png" }) {
        childImageSharp {
          fluid(maxWidth: 710) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFlavorTruffle: file(relativePath: { eq: "flavor-truffle.png" }) {
        childImageSharp {
          fluid(maxWidth: 710) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageFlavorLemon = data.imageFlavorLemon.childImageSharp.fluid
  const imageFlavorUme = data.imageFlavorUme.childImageSharp.fluid
  const imageFlavorChili = data.imageFlavorChili.childImageSharp.fluid
  const imageFlavorWasabi = data.imageFlavorWasabi.childImageSharp.fluid
  const imageFlavorTruffle = data.imageFlavorTruffle.childImageSharp.fluid

	const StyledSlick = styled.div`
		margin: 2em auto;
		z-index: -1;
		.slick-slider {
			overflow: hidden;
		.slick-list {
			margin-bottom: 1em;
			.slick-slide {
				transition: .3s;
				text-align: center;
				.flavor-box {
					max-width: 300px;
					margin: auto;
					padding: 7em 1em 0 1em;
					opacity: 0.7;
					.flavor-image {
						margin: auto;
						transition: .3s;
						width: 100%;
						img {
							min-width: 250px;
						}
					}
					.flavor-text {
						margin-top: 4em;
						.flavor-header {
							.flavor-header-jp {
								display: flex;
								align-items: center;
								justify-content: center;
								margin: auto;
								.flavor-icon {
									margin-right: 5px;
									width: 24px;
									height: 24px;
									img {
										width: 100%;
									}
								}
								.flavor-name {
									font-size: 1.4rem;
									font-weight: bold;
									margin-bottom: 0.2rem;
								}
							}
							.flavor-name-en {
								font-size: 0.9rem;
							}
						}
						.flavor-description {
							font-size: 0.8rem;
							margin-top: 1em;
						}
					}
				}
				@media (max-width: 360px) {
					.flavor-box {
						max-width: 260px;
					}
				}
				@media (min-width: 768px) {
					.flavor-box {
						max-width: 480px;
						.flavor-image {
							img {
								min-width: 400px;
							}
						}
						.flavor-text {
							.flavor-header {
								.flavor-header-jp {
									.flavor-icon {
										margin-right: 8px;
										width: 28px;
										height: 28px;
									}
									.flavor-name {
										font-size: 1.6rem;
									}
								}
							}
							.flavor-description {
								font-size: 1rem;
							}
						}
					}
				}
				@media (min-width: 1024px) {
					.flavor-box {
						max-width: 600px;
						padding: 8em 3em 0 3em;
					}
				}
			}
			.slick-center {
				.flavor-box {
					opacity: 1;
					.flavor-image {
						transform: scale(1.4);
					}
				}
			}
		}

		/* Dots */
		.slick-dots {
			position: absolute;
			text-align: center;
			top: 0;
			margin: auto;
			left: 0;
			right: 0;
			height: 30px;
			transition: .3s;
			@media (min-width: 768px) {
				max-width: 500px;
			}
			li {
				position: relative;
				display: inline-block;
				width: auto;
				margin: 0 10px;
				transition: .3s;
				font-size: 0.8rem;
				line-height: 1;
				cursor: pointer;
				div {
					color: #555;
					display: inline-block;
					padding-left: 20px;
					transition: .3s;
					position: relative;
					height: auto;
					vertical-align: middle;
					&:after {
						position: absolute;
						content: "";
						left: -10px;
						right: 0;
						top: 0;
						bottom: 0;
						border-left: 1px solid #333;
						transition: .3s;
					}
				}
				&:before {
					content: "";
					position: absolute;
					background-size: 100%;
					background-position: left center;
					background-repeat: no-repeat;
					width: 15px;
					height: 15px;
					transition: .3s;
					filter: grayscale(100%);
				}
				&:first-child {
					&:before {
						background-image: url(${iconLemon});
					}
					div {
						&:after {
							display: none;
						}
					}
				}
				&:nth-child(2) {
					&:before {
						background-image: url(${iconUme});
					}
				}
				&:nth-child(3) {
					&:before {
						background-image: url(${iconChili});
					}
				}
				&:nth-child(4) {
					&:before {
						background-image: url(${iconWasabi});
					}
				}
				&:nth-child(5) {
					&:before {
						background-image: url(${iconTruffle});
					}
				}
				@media (max-width: 360px) {
					margin: 0 8px;
					font-size: 0.8rem;
					&:before {
						width: 12px;
						height: 12px;
						top: 1px;
					}
					div {
						padding-left: 15px;
						&:after {
							left: -8px;
						}
					}
				}
				@media (min-width: 768px) {
					margin: 0 15px;
					font-size: 1rem;
					div {
						&:after {
							left: -15px;
						}
					}
				}
			}
			.slick-active {
				&:before {
					filter: grayscale(0%);
				}
				div {
					transition: .3s;
					color: #333;
				}
			}
		}

		/* Arrows */
		.slick-prev,
		.slick-next {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			cursor: pointer;
			height: 100%;
			width: 15%;

			color: transparent;
			outline: none;
		}
		.slick-prev {
			left: 0;
			z-index: 10;
		}
		.slick-next {
			right: 0;
		}
		.slick-prev:before, 
		.slick-next:before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			display: block;
			margin-top: auto;
			margin-right: 0;
			margin-bottom: auto;
			margin-left: 0;
			width: 12px;
			height: 12px;
			transform: rotate(45deg);
		}
		.slick-prev:before {
			left: 15px;
			border-bottom: 2px solid #0a0a0a;
			border-left: 2px solid #0a0a0a;
		}
		.slick-next:before {
			right: 15px;
			border-top: 2px solid #0a0a0a;
			border-right: 2px solid #0a0a0a;
		}
		@media (min-width: 768px) {
			.slick-prev,
			.slick-next {
				width: 15%;
			}
			.slick-prev:before {
				left: 85%;
				width: 20px;
				height: 20px;
			}
			.slick-next:before {
				right: 85%;
				width: 20px;
				height: 20px;
			}
		}
		@media (min-width: 1024px) {
			.slick-prev,
			.slick-next {
				width: 25%;
			}
		}
	}
`

	let labels = ['レモン', '梅', '唐辛子', 'わさび', 'トリュフ']
	const settings = {
		customPaging: function(index) {
			return (
				<div>
					{labels[index]}
				</div>
			);
		},
		dots: true,
		dotsClass: "slick-dots slick-thumb variable-width",
		className: "center",
		variableWidth: true,
		centerMode: true,
		centerPadding: 0,
		infinite: true,
		speed: 800,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return(
		<>
			<StyledSlick>
				<Slider {...settings}>

					<FlavorItem
						flavorSlug="lemon"
						flavorImage={imageFlavorLemon}
						flavorIcon={iconLemon}
						flavorName="いか天レモン味"
						flavorNameEn="LEMON"
						flavorDescription="揚げ物と相性抜群の瀬戸内海レモンの酸味を効かせて爽やかに仕上げました。レモンの皮のほろ苦さもアクセントに。"
					/>

					<FlavorItem
						flavorSlug="ume"
						flavorImage={imageFlavorUme}
						flavorIcon={iconUme}
						flavorName="いか天梅味"
						flavorNameEn="UME"
						flavorDescription="日本で古くから愛されてきた梅昆布茶で風味付けしました。酸味の奥に昆布のうま味がじわっと広がる懐かしい味わい。"
					/>

					<FlavorItem
						flavorSlug="chili"
						flavorImage={imageFlavorChili}
						flavorIcon={iconChili}
						flavorName="いか天唐辛子味"
						flavorNameEn="CHILI PEPPER"
						flavorDescription="サクっとしたイカ天の香ばしさに、唐辛子のピリっとした辛みがマッチする逸品。思わずビールも進んでしまいそう。"
					/>

					<FlavorItem
						flavorSlug="wasabi"
						flavorImage={imageFlavorWasabi}
						flavorIcon={iconWasabi}
						flavorName="いか天わさび味"
						flavorNameEn="WASABI"
						flavorDescription="辛さのなかにもほんのり甘味が広がる、長野県安曇野わさびにこだわりました。山の恵みと海の恵みの幸せな出会い。"
					/>

					<FlavorItem
						flavorSlug="truffle"
						flavorImage={imageFlavorTruffle}
						flavorIcon={iconTruffle}
						flavorName="いか天トリュフ味"
						flavorNameEn="TRUFFLE"
						flavorDescription="トリュフオイルを加えて風味を引き出しました。少し意外な組み合わせながら、トリュフの後味がクセになる逸品。"
					/>

				</Slider>
			</StyledSlick>
		</>
	)
}


export default FlavorSlick