import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Fade from "react-reveal/Fade"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
// import ButtonShop from '../components/ButtonShop'
import FlavorSlick from "../components/FlavorSlick"
import BackgroundImage from "../components/BackgroundImage"
import StoresList from "../components/StoresList"
import Twitter from "../components/Twitter"

import imageHeroCopy from "../assets/images/hero-copy.png"
import imageHeroItem from "../assets/images/hero-item.png"
import imageHeroBox from "../assets/images/hero-box.png"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          subTitle
        }
      }
      imageItemPackage: file(relativePath: { eq: "item-package.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO />

      <section className="section-hero">
        <Fade right distance={"100px"} delay={1000}>
          <div className="hero-bg hero-bg--item">
            <img src={imageHeroItem} alt="天麩羅TOKYO商品イメージ" />
          </div>
        </Fade>
        <Fade left distance={"100px"} delay={1200}>
          <div className="hero-bg hero-bg--box">
            <img src={imageHeroBox} alt="天麩羅TOKYO商品イメージ" />
          </div>
        </Fade>
        <Fade bottom distance={"100px"} delay={700}>
          <div className="hero-copy text-center">
            <img src={imageHeroCopy} alt={data.site.siteMetadata.subTitle} />
          </div>
        </Fade>
      </section>

      <section id="concept" className="section-concept">
        <Fade bottom duration={1000} distance={"30px"}>
          <div className="concept__bg--left"></div>
          <div className="concept__bg--right"></div>
        </Fade>
        <h2 className="section-header">CONCEPT</h2>

        <div className="concept-copy container text-center">
          <Fade bottom duration={1000} distance={"30px"}>
            <p>
              海には海の。山には山の。
              <br />
              古くからお土産は、文字通り
              <br />
              その土地の名物を伝えるものでした。
            </p>

            <p>では、TOKYOの名物は？</p>

            <p>
              古いものを生かし、新しいものをとり込み、
              <br />
              進化し続けるこの街の新名物、
              <br />
              それが天麩羅TOKYOです。
            </p>

            <p>
              古来、老若男女に愛されてきた天麩羅は
              <br />
              代表的な日本の味。
            </p>
            <p>
              こだわり抜いたフレーバーと手軽さを備え
              <br />
              TOKYOの今を届けます。
            </p>

            <p>
              ワクワクする味の体験と新しいTOKYOを
              <br />
              大切な誰かにシェアしませんか。
            </p>
          </Fade>
        </div>
      </section>

      <section className="section-item-package container">
        <Fade bottom duration={1000} distance={"30px"}>
          <Image
            fluid={data.imageItemPackage.childImageSharp.fluid}
            alt="天麩羅TOKYOパッケージ"
          />
        </Fade>
      </section>

      <section id="flavor" className="section-flavor">
        <h2 className="section-header">FLAVOR</h2>
        <Fade bottom duration={1000} distance={"30px"}>
          <FlavorSlick />
        </Fade>
      </section>

      <section className="section-item-box text-center">
        <BackgroundImage />
      </section>

      <section id="stores" className="section-stores">
        <h2 className="section-header">STORES</h2>
        <p
          className="text-center"
          style={{ marginBottom: "2em", fontSize: "1rem" }}
        >
          ※天麩羅TOKYOの商品は、姉妹店にて現在販売準備中です
        </p>
        <div className="stores-box-wrap container-wide">
          <StoresList />
        </div>
      </section>

      <section id="stores" className="section-new">
        <h2 className="section-header">WHAT'S NEW</h2>
        <div className="container-narrow">
          <Twitter />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
